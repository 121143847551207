import gsap from "gsap";

const componentAnimation = () => {
    
    const dataAnimations = document.querySelectorAll("[data-animation='default']");

    if (dataAnimations) {
        dataAnimations.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 0, y: 100});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 1, opacity: 1, y: 0});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }

    const dataAnimationsPop = document.querySelectorAll("[data-animation='pop']");    
    if (dataAnimationsPop) {
        dataAnimationsPop.forEach(function (dataAnimationPop) {
            gsap.set(dataAnimationPop, { scale: 0.01});
    
            const observeOptions = {
                root: null,
                threshold: 0.05,
                // rootMargin: "-150px"
            };
    
            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.05) {
                        gsap.to(entry.target, { duration: 1,  scale: 1.0, opacity:1.0, ease: 'power1.inOut' });
                    } else {
                        gsap.to(entry.target, { duration: 1,  scale: 0.01, opacity:0, ease: 'power1.inOut' });
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimationPop);
        });
    }

    const dataAnimationsPopDelayOne = document.querySelectorAll("[data-animation='pop-delay-1']");    
    if (dataAnimationsPopDelayOne) {
        dataAnimationsPopDelayOne.forEach(function (dataAnimationPopDelayOne) {
            gsap.set(dataAnimationPopDelayOne, { scale: 0.01});
    
            const observeOptions = {
                root: null,
                threshold: 0.05,
                // rootMargin: "-150px"
            };
    
            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.05) {
                        gsap.to(entry.target, { duration: 1,  scale: 1.0, opacity:1.0, ease: 'power1.inOut', delay: 0.2});
                    } else {
                        gsap.to(entry.target, { duration: 1,  scale: 0.01, opacity:0, ease: 'power1.inOut', delay: 0.2 });
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimationPopDelayOne);
        });
    }
    const dataAnimationsPopDelayTwo = document.querySelectorAll("[data-animation='pop-delay-2']");    
    if (dataAnimationsPopDelayTwo) {
        dataAnimationsPopDelayTwo.forEach(function (dataAnimationPopDelayTwo) {
            gsap.set(dataAnimationPopDelayTwo, { scale: 0.01});
    
            const observeOptions = {
                root: null,
                threshold: 0.05,
                // rootMargin: "-150px"
            };
    
            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.05) {
                        gsap.to(entry.target, { duration: 1,  scale: 1.0, opacity:1.0, ease: 'power1.inOut', delay: 0.4});
                    } else {
                        gsap.to(entry.target, { duration: 1,  scale: 0.01, opacity:0, ease: 'power1.inOut', delay: 0.4 });
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimationPopDelayTwo);
        });
    }

    window.addEventListener('scroll', function() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var circles = document.querySelectorAll('.circle-scroll');
        circles.forEach(function(circle) {
            circle.style.transform = 'translateY(' + (-scrollTop * 1.4) + 'px)';
        });
    });

};

export default componentAnimation;
